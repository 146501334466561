import { Button, Typography } from "@mui/material";
import * as React from "react";
import { AiqConfigurations } from "../../redux/aiqConfigurations";
import udiImage from "../../assets/images/TRAQinform-UDI-info-1_10_1.png";


export const Home: React.FunctionComponent = () => (
  <div>
    <Typography data-cy="AppTitle" variant="h3">TRAQinform IQ</Typography>
    <Typography variant="h5">Scan Processing Client</Typography>
    <Typography data-cy="Version" variant="subtitle2">
      <strong>Version:</strong> {AiqConfigurations.version}
    </Typography>
    <Typography data-cy="BuildSHA" variant="subtitle2">
      <strong>Build Commit SHA:</strong> {AiqConfigurations.buildNumber}
    </Typography>
    <Typography data-cy="BuildTime" variant="subtitle2">
      <strong>Build date/time:</strong> {AiqConfigurations.buildTime}
    </Typography>
    <img src={udiImage} alt={"AIQ Solutions UDI Info"} width={750} />

  </div>
);

