/**
 * An interface for a raw ROI DTO.
 */
interface IRawRoiDto {
  roiIndex: number;
  skeletalSegmentNumber: number;
  anatomySegmentSysName: string;
  roiMin: number | null;
  roiMax: number | null;
  roiTotal: number | null;
  roiMean: number | null;
  roiStd: number | null;
  roiVolume: number | null;
  roiLAD: number | null;
  roiSAD: number | null;
  roiCenterCoordinates: [] | null;
  slbScore: number | null;
  partial: boolean;
  deleted: boolean;
  deletedReason: string | null;
}

/**
 * An interface for a client-side ROI view model.
 */
export interface IClientSideRoiViewModel {
  roiId: string;
  skeletalSegmentNumber: number;
  anatomySegmentSysName: string;
  roiMin: number | null;
  roiMax: number | null;
  roiTotal: number | null;
  roiMean: number | null;
  roiStd: number | null;
  roiVolume: number | null;
  roiLAD: number | null;
  roiSAD: number | null;
  roiCenterCoordinates: [] | null;
  slbScore: number | null;
  partial: boolean;
  isDeleted: boolean;
  deleteReason: string | null;
  isSelected: boolean;
}

/**
 * Maps ROIs to client-side view models.
 * @param dtos The raw ROI DTOs.
 * @returns The mapped ROI client-side view models.
 */
export function mapToRoiClientSideViewModels(dtos: Array<IRawRoiDto>): Array<IClientSideRoiViewModel> {
  const vms = dtos.map(v => {
    const vm: IClientSideRoiViewModel = {
      roiId: v.roiIndex.toString(),
      skeletalSegmentNumber: v.skeletalSegmentNumber,
      anatomySegmentSysName: v.anatomySegmentSysName,
      roiMin: v.roiMin,
      roiMax: v.roiMax,
      roiTotal: v.roiTotal,
      roiMean: v.roiMean,
      roiStd: v.roiStd,
      roiLAD: v.roiLAD,
      roiSAD: v.roiSAD,
      roiCenterCoordinates: v.roiCenterCoordinates,
      roiVolume: v.roiVolume,
      partial: v.partial,
      slbScore: v.slbScore ?? 0,
      isDeleted: v.deleted ?? false,
      deleteReason: v.deletedReason ?? null,
      isSelected: false
    };
    return vm;
  });
  return vms;
}

/**
 * Maps ROIs to raw ROI DTOs.
 * @param vms The ROI client-side view models.
 * @returns The mapped raw ROI DTOs.
 */
export function mapToRawRoiDtos(vms: Array<IClientSideRoiViewModel>): Array<IRawRoiDto> {
  const dtos = vms.map(v => {
    const dto: IRawRoiDto = {
      roiIndex: Number.parseInt(v.roiId),
      skeletalSegmentNumber: v.skeletalSegmentNumber,
      anatomySegmentSysName: v.anatomySegmentSysName,
      roiMin: v.roiMin,
      roiMax: v.roiMax,
      roiTotal: v.roiTotal,
      roiMean: v.roiMean,
      roiStd: v.roiStd,
      roiLAD: v.roiLAD,
      roiSAD: v.roiSAD,
      roiCenterCoordinates: v.roiCenterCoordinates,
      roiVolume: v.roiVolume,
      partial: v.partial,
      slbScore: v.slbScore,
      deleted: v.isDeleted,
      deletedReason: v.deleteReason,
    };
    return dto;
  });
  return dtos;
}

/**
 * Loads client-side ROI view models using the specified temporary URI to a JSON file containing raw ROI DTOs.
 * @param uri The temporary URI to the JSON file.
 * @returns The loaded client-side ROI view models.
 */
export async function loadClientSideRoiViewModels(uri: string) {
  const response = await fetch(uri);
  const dtos: Array<IRawRoiDto> = await response.json();
  const vms = mapToRoiClientSideViewModels(dtos);
  return vms;
}
